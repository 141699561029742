.table{

   .radio,
   .checkbox{
        position: relative;
        height: 20px;
        display: block;
        width: 20px;
        padding: 0px 0px;
        margin: 0px 5px;
        text-align: center;

        .icons{
            left: 5px;
        }
   }
   > thead > tr > th,
   > tbody > tr > th,
   > tfoot > tr > th,
   > thead > tr > td,
   > tbody > tr > td,
   > tfoot > tr > td{
       padding: 12px 8px;
       vertical-align: middle;
   }
   // MANU : aici este ceva schimbat!!!
   > tbody > tr > td{
       font-size: 14px;
   }
    > thead > tr > th{
        border-bottom-width: 1px;
        font-size: $font-size-small;
        text-transform: uppercase;
        color: $dark-gray;
        font-weight: $font-weight-normal;
        padding-bottom: 5px;
    }

   .td-actions .btn{
        @include opacity(0.36);

        &.btn-xs{
            padding-left: 3px;
            padding-right: 3px;
        }
    }
    .td-actions{
        min-width: 90px;
    }

    > tbody > tr{
        position: relative;

        &:hover{
            .td-actions .btn{
                @include opacity(1);
            }
        }
    }
}
